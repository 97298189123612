
import { Component, Vue } from "vue-property-decorator";
import AdminTable from "@/components/admin/AdminTable.vue";
import AdminSteamBotSelector from "@/components/admin/AdminSteamBotSelector.vue";
import * as _ from "lodash";
import multipleMutate from "@/mixins/multipleMutate";

@Component({
  components: {
    AdminTable,
    AdminSteamBotSelector
  },
  mixins: [multipleMutate]
})
export default class AdminSteamBotOffer extends Vue {
  selectedSteamBot: any = null;
  selected: any[] = [];
  columns = [
    {
      name: "_id",
      required: true,
      label: "name",
      field: "_id",
      align: "left",
      type: "string"
    },
    {
      name: "price",
      required: true,
      label: "price",
      field: "price",
      align: "left",
      type: "number",
      editable: true
    },
    {
      name: "priceInUsd",

      label: "priceInUsd",
      field: "priceInUsd",
      align: "left",
      type: "number",
      editable: true
    },
    {
      name: "offerId",
      align: "center",
      label: "offerId",
      field: "offerId",
      editable: true,
      type: "string"
    },
    {
      name: "steamItemsIds",
      align: "center",
      label: "steamItemsIds",
      field: "steamItemsIds",
      type: "key-value",
      format: (val, row) => {
        const result = val.reduce((acc, steamItemId, index) => {
          if (typeof acc[steamItemId] !== "undefined") {
            acc[steamItemId][1]++;
          } else {
            acc[steamItemId] = row.steamItemsPrices
              ? [row.steamItemsPrices[index] || 0, 1]
              : [0, 1];
          }
          return acc;
        }, {});
        Object.keys(result).forEach(key => {
          result[key] = result[key].join("x");
        });
        return result;
      }
    },
    {
      name: "partnerUserId",
      align: "center",
      label: "partnerUserId",
      field: "partnerUserId",
      type: "string"
    },
    {
      name: "type",
      align: "center",
      label: "type",
      field: "type",
      type: "string"
    },
    {
      name: "appId",
      align: "center",
      label: "appId",
      field: "appId",
      type: "number"
    },
    {
      name: "steamBotId",
      align: "center",
      label: "steamBotId",
      field: "steamBotId",
      type: "string"
    },
    {
      name: "partnerSteamId",
      align: "center",
      label: "partnerSteamId",
      field: "partnerSteamId",
      type: "string"
    },
    {
      name: "state",
      align: "center",
      label: "state",
      field: "state",
      editable: true,
      type: "number"
    },
    {
      name: "createdAt",
      align: "center",
      label: "createdAt",
      field: "createdAt",
      type: "string"
    },
    {
      name: "paymentId",
      align: "center",
      label: "paymentId",
      field: "paymentId",
      type: "string",
      editable: true
    },
    {
      name: "userId",
      align: "center",
      label: "userId",
      field: "userId",
      type: "string",
      editable: true
    }
  ];

  get lastSelected() {
    return _.last(this.selected) || null;
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AdminTable',{attrs:{"id":"admin-steam-bot-offer","columns":_vm.columns,"title":`Коллекция steambotoffers`,"model-name":"steamBotOfferModel"},on:{"update:selected":value => (_vm.selected = value)},scopedSlots:_vm._u([{key:"top-right",fn:function({ query, search }){return [_c('AdminSteamBotSelector',{attrs:{"value":_vm.selectedSteamBot},on:{"update:value":[function($event){_vm.selectedSteamBot=$event},value => search(value ? { steamBotId: value._id } : null)]}}),_c('ApolloMutation',{attrs:{"mutation":gql => gql`
          mutation notifyPartner($offerId: ID!) {
            notifyPartner(offerId: $offerId)
          }
        `},on:{"error":error =>
          _vm.$q.notify({
            message: error.message,
            multiLine: true,
            type: 'negative'
          }),"done":() => {
          _vm.$q.notify({
            message: 'Успех',
            multiLine: true,
            type: 'positive'
          });
        }},scopedSlots:_vm._u([{key:"default",fn:function({ mutate, loading, error }){return [_c('q-btn',{attrs:{"label":"Уведомить партнера","color":"primary","disabled":!_vm.lastSelected,"push":"","size":"md","rounded":"","loading":loading},on:{"click":function($event){_vm.multipleMutate(
              mutate,
              _vm.selected.map(v => ({ offerId: v._id }))
            )}}})]}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }